<template>
    <div class="homeScreen relative h-full pt-10">
        <div class="absolute z-0 top-0 h-full w-full bg-black opacity-50"></div>
        <div class="relative z-10 h-full flex flex-col">
            <div class="text-white text-2xl text-center mt-16">{{ today }}</div>
            <div class="text-white text-sm text-center mt-16">
                <p v-if="newMessages">You have <b>{{ newMessages }} unread messages</b></p>
                <p v-if="hasUssdMenu">You have a pending <b>ussd request</b></p>
            </div>
            <div class="flex flex-row justify-center mt-auto mb-12">
                <div
                    @click="launchApplication('calling')"
                    class="h-12 w-12 rounded-lg shadow-lg bg-white flex justify-center items-center cursor-pointer">
                    <svg class="h-8 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>
                </div>
                <div
                    @click="launchApplication('messaging')"
                    class="h-12 w-12 ml-12 rounded-lg shadow-lg bg-white flex justify-center items-center cursor-pointer">
                    <svg class="h-8 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"/></svg>
                </div>
                <div
                    @click="launchApplication('payments')"
                    class="h-12 w-12 ml-12 rounded-lg shadow-lg bg-white flex justify-center items-center cursor-pointer">
                    <svg class="h-8 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-9-1c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm13-6v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"/></svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapGetters,
} from 'vuex';

export default {
    name: 'HomeScreen',

    props: {
        launchApplication: {
            type: Function,
            default: () => () => {},
        },
    },

    computed: {
        ...mapGetters('simulator/ussd', {
            hasUssdMenu: 'hasMenu',
        }),

        ...mapGetters('simulator/messaging', {
            newMessages: 'unreadMessages',
        }),
    },

    data() {
        const date = new Date();
        const today = new Intl.DateTimeFormat('en', { weekday: 'long', month: 'short', day: 'numeric' }).format(date);

        return {
            today,
        };
    },
};
</script>
